import React from "react";
import { Link, NavLink } from "react-router-dom";

const Nav = () => {
  return (
    <ul className="mainmenu">
      <li>
        <NavLink to="/">Home</NavLink>
      </li>

      <li>
        <NavLink to="/about">About</NavLink>
      </li>

      <li>
        <NavLink to="/gallery">Gallery</NavLink>
      </li>

      <li>
        <NavLink to="/event">Event</NavLink>
      </li>

      <li className="has-droupdown">
        <Link to="#">E-learning</Link>
        <ul className="submenu">
          <li>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://moodle.cra.ac.th/login/index.php"
            >
              Moodle
            </a>
          </li>
          <li>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.canva.com/design/DAFFDuH1mRc/asxbJ42f4H1jJNEEc1H2oQ/view?utm_content=DAFFDuH1mRc&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink"
            >
              How to ues
            </a>
          </li>
        </ul>
      </li>

      <li className="has-droupdown">
        <Link to="#">Certificate</Link>
        <ul className="submenu">
          <li>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://script.google.com/macros/s/AKfycbyaCBDmExLmLiYpG5sA_MKsIU7TwshDQg7CoVLx2UcVxAxbv2Vu4YGb3eLIiwKnOhEW/exec"
            >
              Certificate IAOC
            </a>
          </li>
          <li>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://script.google.com/macros/s/AKfycbwLVhf_8Qa5ILfflAYkjvYYoLz48hnFwjIAFbBNg8-pYdUloCnMAYh61nJgJkPgbcc/exec"
            >
              Certificate IAEA
            </a>
          </li>
        </ul>
      </li>

      <li>
        <NavLink to="/contact-me">Contact</NavLink>
      </li>
    </ul>
  );
};
export default Nav;
