import React, { useState, useEffect } from "react";
import { useSearchParams, useLocation } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Swal from "sweetalert2";
import { FileUploader } from "react-drag-drop-files";

import SEO from "../../../../common/SEO";
import Layout from "../../../../common/Layout";
import BreadcrumbOne from "../../../../common/breadcrumb/BreadcrumbOne";
import { getStatus } from "../../../../utils";

import { TableSkeleton } from "../../../../components/skeleton/Skeleton";

const CheckRegistration = () => {
  const { pathname } = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [chkrecipe, setChkRecipe] = useState(false);
  const [fileSlip, setFileSlip] = useState(null);
  const [data, setData] = useState();
  const [notFound, setNotFound] = useState();
  const [searchParams, setSearchParams] = useSearchParams();

  const eventID = Number(searchParams.get("e"));
  const email = searchParams.get("email");
  window.scrollTo({ top: 0, behavior: "smooth" });

  const BreadcrumbItems = [
    { url: "/", label: "Home" },
    { url: "/event", label: "Event" },
    { url: "/check-registration", label: "Check Registration", active: true },
  ];

  const validation = Yup.object().shape({
    email: Yup.string()
      .max(200, "Must be at most 200 characters")
      .email("Invalid email")
      .required("Email is Required "),
  });

  const validationUpload = Yup.object().shape({
    recipe: Yup.string().max(1000, "must be at most 1000 characters"),
    tax_id: Yup.string().max(100, "must be at most 100 characters"),
  });

  useEffect(() => {
    if (email) {
      fetchData(email);
    }
  }, [email]);

  const fetchData = async (email) => {
    await setData("");
    await setIsLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_URL_API}chkRegis?e=${eventID}&email=${email}`
      )
      .then((res) => {
        if (res.status === 200) {
          setData(res.data.data[0]);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);

        if (err.response.status === 404) {
          setNotFound(true);
        }
      });
  };

  const handleChangeFileSlip = (file) => {
    setFileSlip(file);
  };

  const RenderContent = () => {
    if (isLoading) {
      return (
        <div
          style={{
            margin: "auto",
            padding: 10,
            maxWidth: "720px",
          }}
        >
          <TableSkeleton rows={3} columns={2} />
        </div>
      );
    } else {
      if (data) {
        return (
          <div className="section-table">
            <table className="table">
              <tbody>
                <tr>
                  <th scope="row">Name :</th>
                  <td>
                    {data?.fname_en} {data?.lname_en}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Email :</th>
                  <td>{data?.email}</td>
                </tr>

                {data?.status === "2" ? (
                  <tr>
                    <th scope="row">Upload :</th>
                    <td>
                      <div className="col-lg-12 mb-4">
                        <Formik
                          initialValues={{
                            recipe: "",
                            tax_id: "",
                          }}
                          validationSchema={validationUpload}
                          onSubmit={async (val) => {
                            let formData = new FormData();
                            formData.append("email", data?.email);
                            formData.append("e", eventID);
                            formData.append("receiptonbehalf", val.recipe);
                            formData.append("tax_id", val.tax_id);
                            formData.append(
                              "file_slip",
                              fileSlip ? fileSlip : null
                            );

                            axios
                              .post(
                                `${process.env.REACT_APP_URL_API}uploadSlip`,
                                formData
                              )
                              .then((res) => {
                                if (res.status === 200) {
                                  Swal.fire({
                                    width: "500px",
                                    title: "Upload successful",
                                    icon: "success",
                                    confirmButtonColor: "#ff6b00",
                                    confirmButtonText: "Accept",
                                  }).then(() => {
                                    window.scrollTo({
                                      top: 0,
                                      behavior: "smooth",
                                    });
                                    setChkRecipe(false);
                                    setIsLoading(false);
                                    setData(res.data.data[0]);
                                  });
                                }
                              })
                              .catch((err) => {
                                Swal.fire({
                                  width: "500px",
                                  title:
                                    err.code === "ERR_BAD_REQUEST"
                                      ? err.response.data.message
                                      : err.message,
                                  // text: ,
                                  icon: err.response ? "warning" : "error",
                                  confirmButtonColor: "#ff6b00",
                                  confirmButtonText: "OK",
                                }).then(() => {
                                  setIsLoading(false);
                                });
                              });
                          }}
                        >
                          {({ errors, touched, resetForm }) => (
                            <Form className="form">
                              <div>
                                <p
                                  style={{
                                    color: "red",
                                    marginBottom: "0",
                                  }}
                                >
                                  * สามารถชำระผ่าน ธนาคารไทยพาณิชย์
                                </p>
                                <p
                                  style={{
                                    color: "red",
                                    marginBottom: "0",
                                  }}
                                >
                                  เลขบัญชี 407-507053-9
                                </p>
                                <p
                                  style={{
                                    color: "red",
                                    marginBottom: "0",
                                  }}
                                >
                                  ชื่อบัญชี ราชวิทยาลัยจุฬาภรณ์ เพื่อ
                                  วิทยาลัยวิทยาศาสตร์การแพทย์เจ้าฟ้าจุฬาภรณ์
                                </p>
                                <p
                                  style={{
                                    color: "red",
                                    marginBottom: "0",
                                  }}
                                >
                                  จำนวน {data?.amont} บาท
                                </p>
                              </div>

                              <div>
                                <p style={{ marginBottom: "4px" }}>
                                  แนบหลักฐานการโอน
                                </p>
                                <FileUploader
                                  classes="drop_area"
                                  required
                                  handleChange={handleChangeFileSlip}
                                  name="fileSlip"
                                  types={["JPEG", "JPG", "PNG"]}
                                  maxSize={10}
                                />
                                <p style={{ marginBottom: "5px" }}>
                                  {fileSlip
                                    ? `File name: ${fileSlip.name}`
                                    : "no files uploaded yet"}
                                </p>
                              </div>

                              {fileSlip ? (
                                <div className="mb-3">
                                  <input
                                    type="checkbox"
                                    id="chkrecipe"
                                    name="chkrecipe"
                                    onClick={() => {
                                      setChkRecipe(!chkrecipe);
                                      resetForm();
                                    }}
                                  />
                                  <label htmlFor="chkrecipe">ขอใบเสร็จ</label>
                                </div>
                              ) : (
                                ""
                              )}

                              {chkrecipe ? (
                                <>
                                  <div className="col-lg-12">
                                    <div className="form-group">
                                      <label>ออกใบเสร็จในนาม</label>
                                      <Field
                                        as="textarea"
                                        className={
                                          "form-control form-control-lg" +
                                          (errors.recipe && touched.recipe
                                            ? " is-invalid"
                                            : "")
                                        }
                                        name="recipe"
                                        placeholder="ออกใบเสร็จในนาม"
                                      />
                                      <ErrorMessage
                                        name="recipe"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </div>
                                  </div>

                                  <div className="col-lg-12">
                                    <div className="form-group">
                                      <label>เลขประจำตัวผู้เสียภาษี</label>
                                      <Field
                                        type="text"
                                        className={
                                          "form-control form-control-lg" +
                                          (errors.tax_id && touched.tax_id
                                            ? " is-invalid"
                                            : "")
                                        }
                                        name="tax_id"
                                        placeholder="เลขประจำตัวผู้เสียภาษี"
                                      />
                                      <ErrorMessage
                                        name="tax_id"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </div>
                                  </div>
                                </>
                              ) : (
                                ""
                              )}

                              <button
                                type="submit"
                                disabled={isLoading}
                                className={`rn-btn edu-btn ${
                                  isLoading ? "disabled-link" : ""
                                }`}
                              >
                                {isLoading ? (
                                  <>
                                    <div className="loader"></div>
                                  </>
                                ) : (
                                  <>
                                    <span>Upload</span>
                                  </>
                                )}
                              </button>
                            </Form>
                          )}
                        </Formik>
                      </div>
                    </td>
                  </tr>
                ) : (
                  ""
                )}

                <tr>
                  <th scope="row">Status :</th>
                  <td>{getStatus(data?.status)}</td>
                </tr>
              </tbody>
            </table>
          </div>
        );
      } else {
        return notFound ? (
          <h1 className="mt-4 text-center">Not Found</h1>
        ) : null;
      }
    }
  };
  return (
    <>
      <SEO title="Check Registration" description="Check Registration" />

      <Layout>
        <BreadcrumbOne title="Check Registration" items={BreadcrumbItems} />

        <div className="container eduvibe-animated-shape">
          <div className="row g-5">
            <div className="section-title text-start mb--60">
              <div className="section-check-registration">
                <Formik
                  initialValues={{
                    email: email ? email : "",
                  }}
                  validationSchema={validation}
                  onSubmit={async (val) => {
                    await fetchData(val.email);
                  }}
                >
                  {({ errors, touched }) => (
                    <Form className="form">
                      {/* Email */}

                      <div className="section-group">
                        <div className="form-group input">
                          <Field
                            autoComplete="off"
                            type="text"
                            className={
                              "form-control form-control-lg" +
                              (errors.email && touched.email
                                ? " is-invalid"
                                : "")
                            }
                            name="email"
                            placeholder="Please fill in your email to verify registration"
                            initialvalues={email}
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>

                        <div className="btn">
                          <button
                            type="submit"
                            disabled={isLoading}
                            className={`rn-btn edu-btn ${
                              isLoading ? "disabled-link" : ""
                            }`}
                          >
                            {isLoading ? (
                              <>
                                <div className="loader"></div>
                              </>
                            ) : (
                              <>
                                <span>Search</span>
                                <i className="icon-search-line"></i>
                              </>
                            )}
                          </button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>

                {RenderContent()}

                <div
                  style={{
                    fontSize: "18px",
                    fontWeight: "bold",
                    color: "#231f40",
                  }}
                  className="text-center"
                >
                  Contact IAOC Team: manot.pen@cra.ac.th
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default CheckRegistration;
