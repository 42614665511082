import React from "react";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../../common/breadcrumb/BreadcrumbOne";
import EventData from "../../data/event/EventData.json";
import EventGrid from "../../components/event/EventTwo";

const Event = () => {
  const EventItems = EventData.slice(0, 9);

  const BreadcrumbItems = [
    { url: "/", label: "Home" },
    { url: "/event", label: "Event", active: true },
  ];
  return (
    <>
      <SEO title="Event" description="Event Iaoc" />
      <Layout>
        <BreadcrumbOne title="Event" items={BreadcrumbItems} />

        <div className="edu-elements-area edu-section-gap bg-color-white">
          <div className="container">
            <div className="row g-5">
              {EventItems.map((item) => (
                <div
                  className="col-lg-4 col-md-6 col-sm-6 col-12"
                  key={item.id}
                >
                  <EventGrid data={item} shadow="enable" />
                </div>
              ))}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Event;
