import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import { DataService } from "../../config/dataService";

const Result = () => {
  return (
    <p className="success-message">
      Thanks for your query. We will contact with you soon.
    </p>
  );
};

const validation = Yup.object().shape({
  contact_name: Yup.string().required("Required"),
  contact_email: Yup.string().email("Invalid email").required("Required"),
  contact_subject: Yup.string().required("Required"),
  contact_comment: Yup.string().required("Required"),
});

function ContactMeForm({ formStyle }) {
  const [result, setResult] = useState(false);

  return (
    <Formik
      initialValues={{
        contact_name: "",
        contact_email: "",
        contact_subject: "",
        contact_comment: "",
      }}
      validationSchema={validation}
      onSubmit={async (val, { resetForm }) => {
        const formData = new FormData();
        formData.append("contact_name", val.contact_name);
        formData.append("contact_email", val.contact_email);
        formData.append("contact_subject", val.contact_subject);
        formData.append("contact_comment", val.contact_comment);

        await DataService.post("contact_add", formData);
        await resetForm();
        await setResult(true);

        await setTimeout(() => {
          setResult(false);
        }, 5000);
      }}
    >
      {({ errors, touched }) => (
        <Form className={`${formStyle}`}>
          <div className="col-lg-6">
            <div className="form-group">
              <Field
                type="text"
                className={
                  "form-control form-control-lg" +
                  (errors.contact_name && touched.contact_name
                    ? " is-invalid"
                    : "")
                }
                name="contact_name"
                placeholder="Name*"
              />
              <ErrorMessage
                name="contact_name"
                component="div"
                className="invalid-feedback"
              />
            </div>
          </div>

          <div className="col-lg-6">
            <div className="form-group">
              <Field
                type="email"
                className={
                  "form-control form-control-lg" +
                  (errors.contact_email && touched.contact_email
                    ? " is-invalid"
                    : "")
                }
                name="contact_email"
                placeholder="Email*"
              />
              <ErrorMessage
                name="contact_email"
                component="div"
                className="invalid-feedback"
              />
            </div>
          </div>

          <div className="col-lg-12">
            <div className="form-group">
              <Field
                type="text"
                className={
                  "form-control form-control-lg" +
                  (errors.contact_subject && touched.contact_subject
                    ? " is-invalid"
                    : "")
                }
                name="contact_subject"
                placeholder="Subject"
              />
              <ErrorMessage
                name="contact_subject"
                component="div"
                className="invalid-feedback"
              />
            </div>
          </div>

          <div className="col-lg-12">
            <div className="form-group">
              <Field
                as="textarea"
                className={
                  "form-control form-control-lg" +
                  (errors.contact_comment && touched.contact_comment
                    ? " is-invalid"
                    : "")
                }
                name="contact_comment"
                placeholder="Your comment"
              />
              <ErrorMessage
                name="contact_comment"
                component="div"
                className="invalid-feedback"
              />
            </div>
          </div>
          <div className="col-lg-12">
            <button className="rn-btn edu-btn" type="submit">
              <span>Submit Now</span>
              <i className="icon-arrow-right-line-right"></i>
            </button>
          </div>
          {result ? (
            <div className="form-group">
              <Result />
            </div>
          ) : null}
        </Form>
      )}
    </Formik>
  );
}

export default ContactMeForm;
