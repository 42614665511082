import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../../common/breadcrumb/BreadcrumbOne";
import EventData from "../../data/event/EventData.json";
import Poster from "../../components/posters/Poster";

const EventDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const eventId = parseInt(id, 10);
  const [eventItem, setEventItem] = useState();

  const BreadcrumbItems = [
    { url: "/", label: "Home" },
    { url: "/event", label: "Event" },
    { url: `/event/${eventId}`, label: "Detail", active: true },
  ];

  useEffect(() => {
    const data = EventData.filter((event) => event.id === eventId);

    if (data.length) {
      setEventItem(data[0]);
    } else {
      navigate("/not-found");
    }
  }, [eventId]);

  return (
    <>
      <SEO title={eventItem?.title} />
      <Layout>
        <BreadcrumbOne title="Event Details" items={BreadcrumbItems} />
        <div className="edu-event-details-area edu-event-details edu-section-gap bg-color-white">
          <div className="container">
            <div className="row g-5">
              <div className="col-lg-12">
                <div className="thumbnail">
                  <img src={eventItem?.image} alt="Event Thumb" />
                </div>
              </div>
            </div>
            <div className="row g-5">
              <div className="col-lg-7">
                <div className="content">
                  <h3 className="title">{eventItem?.title}</h3>
                  <div>
                    <h4>Agenda</h4>
                  </div>
                  <img
                    width={750}
                    className="image-2"
                    src="https://iaocregister.org/iaocapi/upload/img/agenda20240605-3.webp"
                    alt="agenda"
                  />
                  {/* <p>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                    diam nonumy eirmod te invidunt. ut labore et dolore magna
                    aliquyam erat, sed diam voluptua. At vero eos et accusam. et
                    justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                    sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                    ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
                    nonumy eirmod tempor invidunt ut labore et dolore magna
                    aliquyam erat, sed diam voluptua. At vero eos et accusam et
                    justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                    sea takimata sanctus est Lorem ipsum dolor sit amet.
                  </p>
                  <p>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                    diam nonumy eirmod tempor invidunt ut labore et dolore magna
                    aliquyam erat, sed diam voluptua. At vero eos et accusam et
                    justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                    sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                    ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
                    nonumy eirmod tempor invidunt ut labore.
                  </p> */}
                  {/* <ul className="column-gallery gallery-column-2">
                    <li>
                      <img
                        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQJ76SasQj2u3n3Z04s0DTX3Ho4Vq_D-ArUJJffSCJTRw&s"
                        alt="Gallery Images"
                      />
                    </li>
                    <li>
                      <img
                        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQJ76SasQj2u3n3Z04s0DTX3Ho4Vq_D-ArUJJffSCJTRw&s"
                        alt="Gallery Images"
                      />
                    </li>
                  </ul>
                  <h4>Four major elements that we offer:</h4>
                  <ul className="list-style-1 mb--20">
                    <li>
                      <i className="icon-checkbox-circle-fill-solid"></i>{" "}
                      Struggling to sell one multi-million dollar home currently
                      on the market
                    </li>
                    <li>
                      <i className="icon-checkbox-circle-fill-solid"></i> The
                      point of using Lorem Ipsum is that it has a normal
                      distribution of letters.
                    </li>
                    <li>
                      <i className="icon-checkbox-circle-fill-solid"></i>{" "}
                      Publishing packages and web page editors now use their
                      default model text.
                    </li>
                    <li>
                      <i className="icon-checkbox-circle-fill-solid"></i>{" "}
                      Publishing packages and web page editors now use their
                      default model text.
                    </li>
                  </ul>
                  <p>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                    diam nonumy eirmod te invidunt. ut labore et dolore magna
                    aliquyam erat, sed diam voluptua. At vero eos et accusam. et
                    justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                    sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                    ipsum dolor sit amet, consetetur sadipscing elitr.
                  </p> */}
                </div>

                {/* <div className="section-option-register">
                  <div className="box">
                    <div>
                      <div>ลงทะเบียนทั่วไป</div>
                      <div>จำนวน 5000 บาท</div>
                    </div>
                    <button>register</button>
                  </div>
                  <div className="box">
                    <div>
                      <div>
                        <div>ลงทะเบียนแพทย์ประจำบ้าน แพทย์ประจำบ้านต่อยอด</div>
                        <div>จำนวน 4500 บาท</div>
                      </div>
                    </div>
                    <button>register</button>
                  </div>
                  <div className="box">
                    <div>
                      <div>ลงทะเบียนต่างชาติ 5000 THB.</div>
                      <div>5000 THB.</div>
                    </div>
                    <button>register</button>
                  </div>
                </div> */}
              </div>
              <div className="col-lg-5">
                <div className="eduvibe-sidebar">
                  <div className="eduvibe-widget eduvibe-widget-details">
                    <h5 className="title">Event Detail</h5>
                    <div className="widget-content">
                      <ul>
                        {eventItem?.startDate && (
                          <li>
                            <span>
                              <i className="icon-calendar-2-line"></i> Start
                              Date
                            </span>
                            <span>{eventItem?.startDate}</span>
                          </li>
                        )}
                        {eventItem?.endDate && (
                          <li>
                            <span>
                              <i className="icon-calendar-2-line"></i> End Date
                            </span>
                            <span>{eventItem?.endDate}</span>
                          </li>
                        )}
                        {eventItem?.startTime && (
                          <li>
                            <span>
                              <i className="icon-time-line"></i> Start Time
                            </span>
                            <span>{eventItem?.startTime}</span>
                          </li>
                        )}
                        {eventItem?.endTime && (
                          <li>
                            <span>
                              <i className="icon-time-line"></i> End Time
                            </span>
                            <span>{eventItem?.endTime}</span>
                          </li>
                        )}
                        {/* {eventItem?.registered && (
                          <li>
                            <span>
                              <i className="icon-user-line"></i> Registered
                            </span>
                            <span>{eventItem?.registered}</span>
                          </li>
                        )} */}
                        {eventItem?.shortLocation && (
                          <li>
                            <span>
                              <i className="icon-map-pin-line"></i> Location
                            </span>
                            <span>{eventItem?.shortLocation}</span>
                          </li>
                        )}
                      </ul>
                      {/* 
                      <div className="read-more-btn mt--45">
                        <Link
                          className="edu-btn btn-bg-alt w-100 text-center"
                          to="#"
                        >
                          Price: $79.00
                        </Link>
                      </div> */}

                      <div className="read-more-btn mt--15">
                        {eventItem?.status ? (
                          <>
                            <Link
                              className="edu-btn w-100 text-center mb-3"
                              to={`/event/register?e=${eventItem?.id}`}
                            >
                              Register
                            </Link>
                          </>
                        ) : (
                          <Link
                            className="edu-btn disabled-link w-100 text-center"
                            to="#"
                          >
                            Close
                          </Link>
                        )}
                        {eventItem?.status ? (
                          <>
                            <Link
                              className="edu-btn w-100 text-center"
                              to={`/event/check-registration?e=${eventItem?.id}`}
                            >
                              Check Registration
                            </Link>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                      {/* 
                      <div className="read-more-btn mt--30 text-center">
                        <div className="eduvibe-post-share">
                          <span>Share: </span>
                          <a className="linkedin" href="#">
                            <i className="icon-linkedin"></i>
                          </a>
                          <a className="facebook" href="#">
                            <i className="icon-Fb"></i>
                          </a>
                          <a className="twitter" href="#">
                            <i className="icon-Twitter"></i>
                          </a>
                          <a className="youtube" href="#">
                            <i className="icon-youtube"></i>
                          </a>
                        </div>
                      </div> */}
                    </div>
                  </div>

                  <div className="event-widget event-widget-details mt--40">
                    <h5 className="title">Map</h5>
                    <div className="widget-content">
                      <div className="google-map">
                        <div className="eduvibe-google-map">
                          <iframe
                            className="radius-small w-100"
                            title="Google Map"
                            src={eventItem?.map}
                            height="290"
                            // frameBorder="0"
                            style={{ border: 0 }}
                            allowFullScreen=""
                            aria-hidden="false"
                            tabIndex="0"
                          ></iframe>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};
export default EventDetails;
