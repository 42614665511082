import React, { useEffect, useState } from "react";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../../common/breadcrumb/BreadcrumbOne";
import PostOne from "../../components/post/PostOne";
import { DataService } from "../../config/dataService";

const BlogGridOne = () => {
  const BreadcrumbItems = [
    { url: "/", label: "Home" },
    { url: "/gallery", label: "Gallery", active: true },
  ];

  const [PostData, setPostData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const res = await DataService.get(`getGalleryAll`);
      setPostData(res.data.data);
    };
    fetchData();
  }, []);

  return (
    <>
      <SEO title="Gallery" description="Gallery" />
      <Layout>
        <BreadcrumbOne title="Gallery" items={BreadcrumbItems} />
        <div className="edu-elements-area edu-section-gap bg-color-white">
          <div className="container">
            <div className="row g-5">
              <div className="col-lg-12">
                <div className="row g-5">
                  {PostData.map((item) => (
                    <div className="col-lg-4 col-md-6 col-12" key={item.g_id}>
                      <PostOne data={item} />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default BlogGridOne;
