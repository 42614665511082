import PropTypes from "prop-types";
import React from "react";
import { Helmet } from "react-helmet-async";

const SEO = ({ title, description }) => {
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
    </>
  );
};
SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

export default SEO;
