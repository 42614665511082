import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Alert } from "react-bootstrap";
import { FcLock } from "react-icons/fc";
import SEO from "../common/SEO";
import { AuthService } from "../config/authService";

const validation = Yup.object().shape({
  email: Yup.string().email(),
});

const ForgotPassword = () => {
  const [load, setLoad] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showErr, setShowErr] = useState(false);
  const [showErrText, setShowErrText] = useState(false);
  return (
    <>
      <SEO title="Forgot Password" description="Forgot Password" />

      <div
        style={{
          backgroundColor: "#F8F9F9",
          padding: "6rem 2.5rem",
          display: "flex",
          height: "100vh",
        }}
      >
        <div className="container d-flex justify-content-center">
          <div className="row">
            <div
              style={{ padding: "2rem 3.5rem", width: "500px" }}
              className="edu-card card-type-3"
            >
              <div className="text-center">
                <h2>
                  <FcLock />
                </h2>

                <h3>Forgot password?</h3>
                <p>No worries, we'll send you reset instructions.</p>
              </div>

              <div className="col-12">
                <Alert show={showSuccess} variant="success">
                  sent email successfully
                </Alert>
                <Alert show={showErr} variant="danger">
                  {showErrText}
                </Alert>
                <Formik
                  initialValues={{
                    email: "",
                  }}
                  validationSchema={validation}
                  onSubmit={async (val, { resetForm }) => {
                    setShowSuccess(false);
                    setShowErr(false);
                    setLoad(true);
                    const formData = new FormData();

                    formData.append("email", val.email);

                    await AuthService.post("sendPasswordResetEmail", formData)
                      .then((res) => {
                        if (res.data.success) {
                          setShowSuccess(true);
                          resetForm();
                          setLoad(false);
                          setInterval(() => {
                            setShowSuccess(false);
                          }, 3000);
                        } else {
                          setLoad(false);
                          setShowErr(true);
                          setShowErrText(res.data.message);
                        }
                      })
                      .catch((e) => console.log(e));
                    await resetForm();
                  }}
                >
                  {({ errors, touched }) => (
                    <Form>
                      <div className="form-group">
                        <label htmlFor="pass">Email</label>
                        <Field
                          type="email"
                          className={
                            "form-control form-control-lg" +
                            (errors.email && touched.email ? " is-invalid" : "")
                          }
                          name="email"
                          placeholder="Enter your email"
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>

                      <div className="text-center mt-5">
                        <button type="submit" className="rn-btn edu-btn">
                          {load ? "loading..." : "Reset password"}
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
