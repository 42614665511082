import React, { useState } from "react";
import FsLightbox from "fslightbox-react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const GalleryItem = ({ data, slide }) => {
  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 0,
  });

  function openLightboxOnSlide(number) {
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: number,
    });
  }

  return (
    <div className="edu-gallery-grid-item grid-metro-item cat--1 cat--3">
      <div className="edu-gallery-grid">
        <div className="inner">
          <div className="thumbnail">
            <LazyLoadImage
              effect="blur"
              src={data.img_url}
              alt="Gallery Thumb"
            />
          </div>
        </div>

        <button
          className="zoom-icon"
          onClick={() => openLightboxOnSlide(slide + 1)}
        >
          <i className="icon-zoom-in-line"></i>
        </button>
        <FsLightbox
          toggler={lightboxController.toggler}
          slide={lightboxController.slide}
          type="image"
          sources={data.sources_img}
        />
      </div>
    </div>
  );
};
export default GalleryItem;
