import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

const Poster = () => {
  return (
    <div className="eduvibe-home-four-service edu-service-area edu-section-gap bg-color-white position-relative">
      <div className="container eduvibe-animated-shape">
        <div className="row row--50">
          <div className="col-lg-12">
            <div className="inner">
              <div className="section-title text-center">
                <span className="header-title">AGENDA</span>
              </div>
            </div>
            <div className="text-center">
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <img
                  width={750}
                  className="image-2"
                  src="https://iaocregister.org/iaocapi/upload/img/agenda20230703-2.jpg"
                  alt="agenda"
                />
              </ScrollAnimation>
            </div>
          </div>
        </div>

        <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
          <div className="shape-image shape-image-1">
            <img
              src={process.env.PUBLIC_URL + "/images/shapes/shape-11-05.png"}
              alt="shape-thumb"
            />
          </div>
          <div className="shape-image shape-image-2">
            <img
              src={process.env.PUBLIC_URL + "/images/shapes/shape-08-01.png"}
              alt="shape-thumb"
            />
          </div>
          <div className="shape-image shape-image-3">
            <img
              src={process.env.PUBLIC_URL + "/images/shapes/shape-30.png"}
              alt="shape-thumb"
            />
          </div>
          <div className="shape shape-1">
            <span className="shape-dot"></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Poster;
