/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { Navigation, Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { useSelector } from "react-redux";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/free-mode";
import { selectAllBanner } from "../../features/banner/bannerSlice";

const CarouselPage = () => {
  const bannerData = useSelector(selectAllBanner);

  const swiperSlide = bannerData
    ? bannerData.map((val, index) => (
        <SwiperSlide key={index}>
          {val.banner_href ? (
            <a rel="noopener noreferrer" target="_blank" href={val.banner_href}>
              <img
                style={{ width: "100%" }}
                alt={val.banner_name}
                src={val.banner_pic}
              />
            </a>
          ) : (
            <img
              style={{ width: "100%" }}
              alt={val.banner_name}
              src={val.banner_pic}
            />
          )}
        </SwiperSlide>
      ))
    : "";

  return (
    <Swiper
      style={{
        "--swiper-navigation-color": "#ECF0F1",
        "--swiper-pagination-color": "#ECF0F1",
      }}
      pagination={{
        type: "progressbar",
      }}
      autoplay={{
        delay: 10000,
        disableOnInteraction: false,
      }}
      navigation={true}
      modules={[Autoplay, Pagination, Navigation]}
      className="mySwiper"
    >
      {/* <SwiperSlide>
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://www.google.com"
        >
          <img
            style={{ width: "100%" }}
            src="https://scontent.fbkk22-8.fna.fbcdn.net/v/t1.6435-9/137261434_1052349405245840_3205411195401389567_n.jpg?_nc_cat=110&ccb=1-7&_nc_sid=8bfeb9&_nc_eui2=AeHSCyAgStjjw6eGJRJnKRswgrHmSX4We1yCseZJfhZ7XGZgN-NCAuEAWFCWN64T3VOJMYBS-M8eMQeoQhjsmRMH&_nc_ohc=A0er90bNl3YAX-s6DuM&_nc_ht=scontent.fbkk22-8.fna&oh=00_AT_gTZYKsE17rnJs6whMFtHO_ZEGzyjfXFmqvcXphHtgoQ&oe=62CF6FA4"
          />
        </a>
      </SwiperSlide> */}
      {swiperSlide}
    </Swiper>
  );
};

export default CarouselPage;
