import React, { useEffect, useState } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Link } from "react-router-dom";
import SectionTitle from "../sectionTitle/SectionTitle";
import PostOne from "../../components/post/PostOne";
import { DataService } from "../../config/dataService";

const HomeFourBlog = () => {
  const [PostData, setPostData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const res = await DataService.get(`getNewsFeed`);
      setPostData(res.data.data);
    };
    fetchData();
  }, []);
  return (
    <div className="eduvibe-home-four-blog bg-image edu-blog-area edu-section-gap">
      <div className="container eduvibe-animated-shape">
        <div className="row">
          <div className="col-lg-6">
            <SectionTitle
              classes="text-start"
              slogan="News"
              title="Our Latest News Feed"
            />
          </div>
          <div className="col-lg-6">
            <div className="load-more-btn text-end">
              <Link className="edu-btn" to="/gallery">
                See All<i className="icon-arrow-right-line-right"></i>
              </Link>
            </div>
          </div>
        </div>
        <div className="row g-5 mt--20">
          {PostData.map((item) => (
            <ScrollAnimation
              animateIn="fadeInUp"
              animateOut="fadeInOut"
              animateOnce={true}
              className="col-lg-4 col-md-6 col-12"
              key={item.g_id}
            >
              <PostOne data={item} />
            </ScrollAnimation>
          ))}
        </div>

        <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
          <div className="shape-image shape-image-1">
            <img
              src={process.env.PUBLIC_URL + "/images/shapes/shape-32.png"}
              alt="shape-thumb"
            />
          </div>
          <div className="shape-image shape-image-2">
            <img
              src={process.env.PUBLIC_URL + "/images/shapes/shape-03-08.png"}
              alt="shape-thumb"
            />
          </div>
          <div className="shape-image shape-image-3">
            <img
              src={process.env.PUBLIC_URL + "/images/shapes/shape-27-01.png"}
              alt="shape-thumb"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeFourBlog;
