import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { DataService } from "../../config/dataService";

const initialState = {
  data: [],
  status: "idle",
  error: null,
};

export const fetchBanner = createAsyncThunk("banner/fetchBanner", async () => {
  const response = await DataService.get("show_banner");
  return response.data.data;
});

const bannerSlice = createSlice({
  name: "banner",
  initialState,
  extraReducers(builder) {
    builder
      .addCase(fetchBanner.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchBanner.fulfilled, (state, action) => {
        state.status = "succeeded";
        // Add any fetched posts to the array
        state.data = state.data.concat(action.payload);
      })
      .addCase(fetchBanner.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
    // .addCase(addNewPost.fulfilled, (state, action) => {
    //   state.posts.push(action.payload);
    // });
  },
});

export default bannerSlice.reducer;

export const selectAllBanner = (state) => state.banner.data;
