import React from "react";

const TableSkeleton = ({ rows, columns }) => {
  const tableRows = [];

  // Generate skeleton rows
  for (let i = 0; i < rows; i++) {
    const tableCells = [];
    for (let j = 0; j < columns; j++) {
      tableCells.push(<td key={`${i}-${j}`} className="skeleton-cell"></td>);
    }
    tableRows.push(<tr key={i}>{tableCells}</tr>);
  }

  return (
    <table className="table-skeleton">
      <tbody>{tableRows}</tbody>
    </table>
  );
};

export { TableSkeleton };
