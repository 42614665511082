import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// import Snowfall from "../../components/snowfall/Snowfall";
import { fetchBanner } from "../../features/banner/bannerSlice";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import Carousel from "../../components/carousel/Carousel";
// import Speaker from "../../components/speaker/Speaker";
import Poster from "../../components/posters/Poster";
import News from "../../components/home-four/HomeFourBlog";

const Home = () => {
  const dispatch = useDispatch();
  const bannerStatus = useSelector((state) => state.banner.status);

  useEffect(() => {
    if (bannerStatus === "idle") {
      dispatch(fetchBanner());
    }
  }, [bannerStatus, dispatch]);

  return (
    <>
      <SEO
        title="Imaging Academic Outreach Center"
        description="IAOC Website"
      />

      {bannerStatus === "succeeded" ? (
        <>
          {/* <Snowfall /> */}
          <Layout>
            <Carousel />
            {/* <Speaker /> */}
            {/* <Poster /> */}
            <News />
          </Layout>
        </>
      ) : (
        <div className="loading-container">
          <div className="spinner"></div>
        </div>
      )}
    </>
  );
};

export default Home;
