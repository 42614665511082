import { useState } from "react";
import { Link } from "react-router-dom";
import Nav from "./Nav";
import HeaderSticky from "./HeaderSticky";
import ResponsiveMenu from "./ResponsiveMenu";

const HeaderOne = ({ styles, disableSticky }) => {
  const [offcanvasShow, setOffcanvasShow] = useState(false);
  const [searchPopup, setSearchPopup] = useState(false);
  const onCanvasHandler = () => {
    setOffcanvasShow((prevState) => !prevState);
  };

  const onSearchHandler = () => {
    setSearchPopup((prevState) => !prevState);
  };

  if (searchPopup) {
    document.body.classList.add("search-popup-active");
  } else {
    document.body.classList.remove("search-popup-active");
  }

  const sticky = HeaderSticky(118);
  const classes = sticky ? "sticky" : "";
  const stickyStatus = disableSticky ? "" : " header-sticky";
  return (
    <>
      <header
        className={`edu-header disable-transparent ${stickyStatus} ${
          styles || ""
        } ${classes || ""}`}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-xl-2 col-md-6 col-6">
              <div className="logo">
                <Link to="/">
                  <img
                    className="logo-light"
                    src={
                      process.env.PUBLIC_URL + "/images/logo/logo-header.png"
                    }
                    alt="Main Logo"
                  />
                </Link>
              </div>
            </div>

            <div className="col-lg-8 d-none d-xl-block">
              <nav className="mainmenu-nav d-none d-lg-block">
                <Nav />
              </nav>
            </div>

            <div className="col-lg-6 col-xl-2 col-md-6 col-6">
              <div className="header-right d-flex justify-content-end">
                <div className="header-quote">
                  <div className="quote-icon quote-search">
                    <button
                      className="search-trigger"
                      onClick={onSearchHandler}
                    >
                      <i className="ri-search-line"></i>
                    </button>
                  </div>
                  <div className="quote-icon quote-user">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="http://iaocregister.org/iaocbackend/"
                    >
                      <i className="ri-user-line"></i>
                    </a>
                  </div>
                  {/* <div className="quote-user d-md-block ml--15 ml_sm--5">
                    <a
                      className="edu-btn btn-medium left-icon header-button"
                      target="_blank"
                      rel="noreferrer"
                      href="http://iaocregister.org/register-iaoc/register"
                    >
                      Certificate
                    </a>
                  </div> */}
                  {/* <div className="quote-user d-md-block ml--15 ml_sm--5">
                    <Link
                      className="edu-btn btn-medium left-icon header-button"
                      // href="https://iaocregister.org/register-iaoc/register"
                      // href="https://iaocregister.org/iaoc-course/register"
                      to="/register"
                    >
                      Register
                    </Link>
                  </div> */}
                  <div className="hamberger quote-icon d-block d-xl-none">
                    <button
                      className="hamberger-button"
                      onClick={onCanvasHandler}
                    >
                      <i className="ri-menu-line"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <ResponsiveMenu
        show={offcanvasShow}
        onClose={onCanvasHandler}
        showSearch={searchPopup}
        onSearch={onSearchHandler}
      />
    </>
  );
};

export default HeaderOne;
