import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Alert } from "react-bootstrap";
import { FcLock, FcOk } from "react-icons/fc";
import SEO from "../common/SEO";
import { AuthService } from "../config/authService";

const validation = Yup.object().shape({
  password: Yup.string()
    .required("No password provided.")
    .min(8, "Password must be 8 characters long")
    .matches(/[0-9]/, "Password requires a number")
    .matches(/[a-z]/, "Password requires a lowercase letter")
    .matches(/[A-Z]/, "Password requires an uppercase letter"),
  // .matches(/[^\w]/, "Password requires a symbol"),
  password_confirm: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Passwords must match"
  ),
});

const Resetpassword = () => {
  const params = new URLSearchParams(window.location.search);
  const [resetSuccess, setResetSuccess] = useState(false);
  const [showErr, setShowErr] = useState(false);
  const [showErrText, setShowErrText] = useState(false);
  const [passwordShown, setPasswordShown] = useState("");

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  return (
    <>
      <SEO title="Reset Password" description="Reset Password" />

      <div
        style={{
          backgroundColor: "#F8F9F9",
          padding: "6rem 2.5rem",
          display: "flex",
          height: "100vh",
        }}
      >
        {resetSuccess ? (
          <>
            <div className="container d-flex justify-content-center">
              <div className="row">
                <div
                  style={{ padding: "2rem 3.5rem", width: "500px" }}
                  className="edu-card card-type-3"
                >
                  <div className="text-center">
                    <h2>
                      <FcOk />
                    </h2>
                    <h3>Password reset</h3>
                    <p>Your password has been successfully reset.</p>
                    <div className="text-center mt-5">
                      <button type="submit" className="rn-btn edu-btn">
                        Continue
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="container d-flex justify-content-center">
              <div className="row">
                <div
                  style={{ padding: "2rem 3.5rem" }}
                  className="edu-card card-type-3"
                >
                  <div className="text-center">
                    <h2>
                      <FcLock />
                    </h2>

                    <h3>Set new password</h3>
                    <p>
                      Your new password must be different to previously used
                      passwords
                    </p>
                  </div>

                  <div className="col-12">
                    <Alert show={showErr} variant="danger">
                      {showErrText}
                    </Alert>
                    <Formik
                      initialValues={{
                        password: "",
                        password_confirm: "",
                      }}
                      validationSchema={validation}
                      onSubmit={async (val, { resetForm }) => {
                        const formData = new FormData();
                        formData.append("email", params.get("email"));
                        formData.append("passwordNew", val.password);
                        formData.append("token", params.get("token"));

                        await AuthService.post("resetnewpassword", formData)
                          .then((res) => {
                            if (res.data.success) {
                              setResetSuccess(true);
                              resetForm();
                            } else {
                              setShowErr(true);
                              setShowErrText(res.data.message);
                            }
                          })
                          .catch((e) => console.log(e));
                        await resetForm();
                      }}
                    >
                      {({ errors, touched }) => (
                        <Form>
                          <div className="form-group">
                            <label htmlFor="pass">New Password</label>
                            <Field
                              type={passwordShown ? "text" : "password"}
                              className={
                                "form-control form-control-lg" +
                                (errors.password && touched.password
                                  ? " is-invalid"
                                  : "")
                              }
                              name="password"
                            />
                            <ErrorMessage
                              name="password"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>

                          <div className="form-group">
                            <label htmlFor="pass">Confirm Password</label>
                            <Field
                              type={passwordShown ? "text" : "password"}
                              className={
                                "form-control form-control-lg" +
                                (errors.password_confirm &&
                                touched.password_confirm
                                  ? " is-invalid"
                                  : "")
                              }
                              name="password_confirm"
                            />
                            <ErrorMessage
                              name="password_confirm"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>

                          <div className="form-group">
                            <div className="input-box">
                              <input
                                id="checkbox-2"
                                type="checkbox"
                                onClick={togglePassword}
                              />
                              <label htmlFor="checkbox-2">Show Password</label>
                            </div>
                          </div>
                          <div className="text-center mt-5">
                            <button type="submit" className="rn-btn edu-btn">
                              Reset password
                            </button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Resetpassword;
