import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { FileUploader } from "react-drag-drop-files";
import axios from "axios";
import Swal from "sweetalert2";

const FormTH = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const fileTypes = ["JPEG", "PNG", "PDF"];
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [chkrecipe, setChkRecipe] = useState(false);
  const [fileSlip, setFileSlip] = useState(null);

  const handleChangeFileSlip = (file) => {
    setFileSlip(file);
  };

  const handleChange = (file) => {
    setFile(file);
  };

  const validation = Yup.object().shape({
    title_en: Yup.string()
      .max(10, "must be at most 10 characters")
      .required("Required"),
    fname_en: Yup.string()
      .max(100, "must be at most 100 characters")
      .required("Required"),
    lname_en: Yup.string()
      .max(100, "must be at most 100 characters")
      .required("Required"),
    title_th: Yup.string()
      .max(100, "must be at most 100 characters")
      .required("Required"),
    fname_th: Yup.string()
      .max(100, "must be at most 100 characters")
      .required("Required"),
    lname_th: Yup.string()
      .max(100, "must be at most 100 characters")
      .required("Required"),
    job: Yup.string()
      .max(100, "must be at most 100 characters")
      .required("Required"),
    job_other: Yup.string()
      .max(50, "must be at most 50 characters")
      .when("job", {
        is: (val) => val === "Other" || val === "Physician, (please Specify)",
        then: Yup.string().required("Required"),
        otherwise: Yup.string().nullable(true), // Optional for other job types
      }),
    cme_credit: Yup.string()
      .max(50, "must be at most 50 characters")
      .when("job", {
        is: (val) => val === "Residency" || val === "Fellowship Training",
        then: Yup.string().nullable(true),
        otherwise: Yup.string().required("Required"), // Optional for other job types
      }),
    email: Yup.string()
      .max(200, "Must be at most 200 characters")
      .email("Invalid email")
      .required("Required"),
    recipe: Yup.string().max(1000, "must be at most 1000 characters"),
    tax_id: Yup.string().max(100, "must be at most 100 characters"),
    food: Yup.string()
      .max(50, "must be at most 50 characters")
      .required("Required"),
    foodallergy: Yup.string().max(300, "must be at most 300 characters"),
  });

  const renderUploadFile = (values, errors, touched, setValues) => {
    if (values.job === "Residency" || values.job === "Fellowship Training") {
      return (
        <>
          <div className="col-lg-12 mb-4">
            <p style={{ marginBottom: 8, color: "red" }}>
              *กรุณาอัปโหลดใบรับรอง
            </p>
            <FileUploader
              classes="drop_area"
              required
              handleChange={handleChange}
              name="file"
              types={fileTypes}
              maxSize={10}
            />
            <p>{file ? `File name: ${file.name}` : "no files uploaded yet"}</p>
          </div>
        </>
      );
    } else if (values.job) {
      return (
        <div className="col-lg-12 mb-4">
          <div>
            <p style={{ color: "red", marginBottom: "0" }}>
              * สามารถชำระผ่าน ธนาคารไทยพาณิชย์
            </p>
            <p style={{ color: "red", marginBottom: "0" }}>
              ชื่อบัญชี ราชวิทยาลัยจุฬาภรณ์ เพื่อ
              วิทยาลัยวิทยาศาสตร์การแพทย์เจ้าฟ้าจุฬาภรณ์
            </p>
            <p style={{ color: "red", marginBottom: "0" }}>
              เลขที่ 407-507053-9
            </p>
            <p style={{ color: "red", marginBottom: "0" }}>จำนวน 5,000 บาท</p>
          </div>

          <div>
            <p style={{ marginBottom: "4px" }}>แนบหลักฐานการโอน</p>
            <FileUploader
              classes="drop_area"
              required
              handleChange={handleChangeFileSlip}
              name="fileSlip"
              types={["JPEG", "JPG", "PNG"]}
              maxSize={10}
            />
            <p style={{ marginBottom: "5px" }}>
              {fileSlip
                ? `File name: ${fileSlip.name}`
                : "no files uploaded yet"}
            </p>
          </div>

          {fileSlip ? (
            <div className="mb-3">
              <input
                type="checkbox"
                id="chkrecipe"
                name="chkrecipe"
                onClick={() => {
                  setChkRecipe(!chkrecipe);
                  setValues({
                    ...values,
                    recipe: "",
                    tax_id: "",
                  });
                }}
              />
              <label htmlFor="chkrecipe">ขอใบเสร็จ</label>
            </div>
          ) : (
            ""
          )}

          {chkrecipe ? (
            <>
              <div className="col-lg-12">
                <div className="form-group">
                  <label>ออกใบเสร็จในนาม</label>
                  <Field
                    as="textarea"
                    className={
                      "form-control form-control-lg" +
                      (errors.recipe && touched.recipe ? " is-invalid" : "")
                    }
                    name="recipe"
                    placeholder="ออกใบเสร็จในนาม"
                  />
                  <ErrorMessage
                    name="recipe"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
              </div>

              <div className="col-lg-12">
                <div className="form-group">
                  <label>เลขประจำตัวผู้เสียภาษี</label>
                  <Field
                    type="text"
                    className={
                      "form-control form-control-lg" +
                      (errors.tax_id && touched.tax_id ? " is-invalid" : "")
                    }
                    name="tax_id"
                    placeholder="ออกใบเสร็จในนาม"
                  />
                  <ErrorMessage
                    name="tax_id"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      );
    } else {
      return;
    }
  };

  return (
    <Formik
      initialValues={{
        title_en: "",
        fname_en: "",
        lname_en: "",
        title_th: "",
        fname_th: "",
        lname_th: "",
        job: "",
        job_other: "",
        cme_credit: "",
        recipe: "",
        tax_id: "",
        email: "",
        food: "",
        foodallergy: "",
      }}
      validationSchema={validation}
      onSubmit={async (val) => {
        setIsLoading(true);
        const eventID = searchParams.get("e");
        let formData = new FormData();
        formData.append("title_en", val.title_en);
        formData.append("fname_en", val.fname_en);
        formData.append("lname_en", val.lname_en);
        formData.append("title_th", val.title_th);
        formData.append("fname_th", val.fname_th);
        formData.append("lname_th", val.lname_th);
        formData.append("job", val.job);
        formData.append("job_other", val.job_other);
        formData.append("cme_credit", val.cme_credit);
        formData.append("file", file ? file : null);
        formData.append("file_slip", fileSlip ? fileSlip : null);
        formData.append("receiptonbehalf", val.recipe);
        formData.append("tax_id", val.tax_id);
        formData.append("food", val.food);
        formData.append("foodallergy", val.foodallergy);
        formData.append("email", val.email);
        formData.append("country", "TH");
        formData.append("lang", "th");
        formData.append(
          "amont",
          val.job === "Residency" || val.job === "Fellowship Training"
            ? 4500
            : 5000
        );
        formData.append("event_id", eventID);

        Swal.fire({
          width: "450px",
          title: "ระบบกำลังประมวลผล",
          html: `<img src="https://global.discourse-cdn.com/sitepoint/original/3X/e/3/e352b26bbfa8b233050087d6cb32667da3ff809c.gif" width="60px"></img>`,
          icon: "info",
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
        })
          .then(
            await axios
              .post(
                `${process.env.REACT_APP_URL_API}membercourse_Add`,
                formData
              )
              .then((res) => {
                if (res.status === 200) {
                  Swal.fire({
                    width: "500px",
                    title: "You have successfully registered",
                    text: "IAOC Course Pediatric Neuro Workshop II: Unwinding the complexity\n IAOC Will Respond Email Thank You",
                    icon: "success",
                    confirmButtonColor: "#ff6b00",
                    confirmButtonText: "Accept",
                  }).then(async () => {
                    await setIsLoading(false);
                    await navigate(
                      `/event/check-registration?e=${eventID}&email=${val.email}`
                    );
                  });
                }
              })
              .catch((err) => {
                Swal.fire({
                  width: "500px",
                  title:
                    err.code === "ERR_BAD_REQUEST"
                      ? err.response.data.message
                      : err.message,
                  // text: ,
                  icon: err.response ? "warning" : "error",
                  confirmButtonColor: "#ff6b00",
                  confirmButtonText: "OK",
                }).then(() => {
                  setIsLoading(false);
                });
              })
          )
          .then(() => {
            setIsLoading(false);
          });
      }}
    >
      {({ values, errors, touched, setValues }) => (
        <Form className="form row">
          {/* title_name_en */}
          <div className="col-lg-12">
            <div className="form-group">
              <label>Title Name (EN)</label>
              <Field
                type="text"
                className={
                  "form-control form-control-lg" +
                  (errors.title_en && touched.title_en ? " is-invalid" : "")
                }
                name="title_en"
                placeholder="Title Name"
              />
              <ErrorMessage
                name="title_en"
                component="div"
                className="invalid-feedback"
              />
            </div>
          </div>

          {/* fname_en */}
          <div className="col-lg-12">
            <div className="form-group">
              <label>First Name (EN)</label>
              <Field
                type="text"
                className={
                  "form-control form-control-lg" +
                  (errors.fname_en && touched.fname_en ? " is-invalid" : "")
                }
                name="fname_en"
                placeholder="First Name"
              />
              <ErrorMessage
                name="fname_en"
                component="div"
                className="invalid-feedback"
              />
            </div>
          </div>

          {/* lname_en */}
          <div className="col-lg-12">
            <div className="form-group">
              <label>Last Name (EN)</label>
              <Field
                type="text"
                className={
                  "form-control form-control-lg" +
                  (errors.lname_en && touched.lname_en ? " is-invalid" : "")
                }
                name="lname_en"
                placeholder="Last Name"
              />
              <ErrorMessage
                name="lname_en"
                component="div"
                className="invalid-feedback"
              />
            </div>
          </div>

          {/* title_th */}
          <div className="col-lg-12">
            <div className="form-group">
              <label>คำนำหน้าชื่อ (TH)</label>
              <Field
                type="text"
                className={
                  "form-control form-control-lg" +
                  (errors.title_th && touched.title_th ? " is-invalid" : "")
                }
                name="title_th"
                placeholder="คำนำหน้าชื่อ"
              />
              <ErrorMessage
                name="title_th"
                component="div"
                className="invalid-feedback"
              />
            </div>
          </div>

          {/* fname_th */}
          <div className="col-lg-12">
            <div className="form-group">
              <label>ชื่อ (TH)*</label>
              <Field
                type="text"
                className={
                  "form-control form-control-lg" +
                  (errors.fname_th && touched.fname_th ? " is-invalid" : "")
                }
                name="fname_th"
                placeholder="ชื่อ"
              />
              <ErrorMessage
                name="fname_th"
                component="div"
                className="invalid-feedback"
              />
            </div>
          </div>

          {/* lname_th */}
          <div className="col-lg-12">
            <div className="form-group">
              <label>นามสกุล (TH)</label>
              <Field
                type="text"
                className={
                  "form-control form-control-lg" +
                  (errors.lname_th && touched.lname_th ? " is-invalid" : "")
                }
                name="lname_th"
                placeholder="นามสกุล"
              />
              <ErrorMessage
                name="lname_th"
                component="div"
                className="invalid-feedback"
              />
            </div>
          </div>

          {/* job */}
          <div className="col-lg-12">
            <div className="form-group">
              <label>อาชีพ</label>
              <Field
                as="select"
                name="job"
                className={
                  "form-control form-control-lg" +
                  (errors.job && touched.job ? " is-invalid" : "")
                }
                onChange={(event) => {
                  setValues({
                    ...values,
                    job: event.target.value,
                    job_other: "",
                    cme_credit: "",
                  });
                }}
              >
                <option hidden>โปรดกรุณาเลือก</option>
                <option value="Radiologist">Radiologist</option>
                <option value="Radiology Trainee">Radiology Trainee</option>
                <option value="Pediatrician">Pediatrician</option>
                <option value="Pediatric Trainee">Pediatric Trainee</option>
                <option value="Technologist">Technologist</option>
                <option value="Physicist">Physicist</option>
                <option value="Nurse">Nurse</option>
                <option value="Physician, (please Specify)">
                  Physician, (please Specify)
                </option>
                <option value="Residency">Residency</option>
                <option value="Fellowship Training">Fellowship Training</option>
                <option value="Other">Other</option>
              </Field>
              <ErrorMessage
                name="job"
                component="div"
                className="invalid-feedback"
              />
            </div>
          </div>

          {/* cme_credit */}
          {values.job === "Radiologist" ||
          values.job === "Radiology Trainee" ||
          values.job === "Pediatrician" ||
          values.job === "Pediatric Trainee" ||
          values.job === "Technologist" ? (
            <>
              <div className="col-lg-12">
                <div className="form-group">
                  <label>เลขที่ใบประกอบโรคศิลป์ </label>
                  <Field
                    type="text"
                    className={
                      "form-control form-control-lg" +
                      (errors.cme_credit && touched.cme_credit
                        ? " is-invalid"
                        : "")
                    }
                    name="cme_credit"
                    placeholder="เลขที่ใบประกอบโรคศิลป์"
                  />
                  <ErrorMessage
                    name="cme_credit"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
              </div>
            </>
          ) : (
            ""
          )}

          {/* job Physician */}
          {values.job === "Physician, (please Specify)" ? (
            <>
              <div className="col-lg-12">
                <div className="form-group">
                  <label>Physician, (please Specify)</label>
                  <Field
                    type="text"
                    className={
                      "form-control form-control-lg" +
                      (errors.job_other && touched.job_other
                        ? " is-invalid"
                        : "")
                    }
                    name="job_other"
                    placeholder="โปรดระบุ"
                  />
                  <ErrorMessage
                    name="job_other"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
              </div>

              <div className="col-lg-12">
                <div className="form-group">
                  <label>
                    เลขที่ใบประกอบโรคศิลป์{" "}
                    <font color="red">(*เพื่อเก็บคะแนน CME)</font>
                  </label>
                  <Field
                    type="text"
                    className={
                      "form-control form-control-lg" +
                      (errors.cme_credit && touched.cme_credit
                        ? " is-invalid"
                        : "")
                    }
                    name="cme_credit"
                    placeholder="เลขที่ใบประกอบโรคศิลป์"
                  />
                  <ErrorMessage
                    name="cme_credit"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
              </div>
            </>
          ) : (
            ""
          )}

          {/* job_other */}
          {values.job === "Other" ? (
            <>
              <div className="col-lg-12">
                <div className="form-group">
                  <label>Other</label>
                  <Field
                    type="text"
                    className={
                      "form-control form-control-lg" +
                      (errors.job_other && touched.job_other
                        ? " is-invalid"
                        : "")
                    }
                    name="job_other"
                    placeholder="โปรดระบุ"
                  />
                  <ErrorMessage
                    name="job_other"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
              </div>
            </>
          ) : (
            ""
          )}

          {renderUploadFile(values, errors, touched, setValues)}

          {/* Email */}
          <div className="col-lg-12">
            <div className="form-group">
              <label>Email</label>
              <Field
                type="text"
                className={
                  "form-control form-control-lg" +
                  (errors.email && touched.email ? " is-invalid" : "")
                }
                name="email"
                placeholder="Email"
              />
              <ErrorMessage
                name="email"
                component="div"
                className="invalid-feedback"
              />
            </div>
          </div>

          {/* Food */}
          <div className="col-lg-12">
            <div className="form-group">
              <label>อาหารกลางวัน</label>
              <Field
                as="select"
                name="food"
                className={
                  "form-control form-control-lg" +
                  (errors.food && touched.food ? " is-invalid" : "")
                }
                maxLength={10}
              >
                <option hidden>โปรดกรุณาเลือก</option>
                <option value="ปกติ">อาหาร ปกติ</option>
                <option value="มังสวิรัติ">อาหาร มังสวิรัติ</option>
                <option value="อิสลาม">อาหาร อิสลาม</option>
              </Field>
              <ErrorMessage
                name="food"
                component="div"
                className="invalid-feedback"
              />
            </div>
          </div>

          {/* foodallergy */}
          <div className="col-lg-12">
            <div className="form-group">
              <label>แพ้อาหาร (ไม่จำเป็น)</label>
              <Field
                type="text"
                className={
                  "form-control form-control-lg" +
                  (errors.foodallergy && touched.foodallergy
                    ? " is-invalid"
                    : "")
                }
                name="foodallergy"
                placeholder="แพ้อาหาร"
              />
              <ErrorMessage
                name="foodallergy"
                component="div"
                className="invalid-feedback"
              />
            </div>
          </div>

          <div
            className="col-lg-12 mt-4"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <button
              disabled={isLoading}
              className={`rn-btn edu-btn ${isLoading ? "disabled-link" : ""}`}
              type="submit"
            >
              {isLoading ? (
                <>
                  <div className="loader"></div>
                  <div className="px-3">Loading...</div>
                </>
              ) : (
                <>
                  <span>Register</span>
                  <i className="icon-arrow-right-line-right"></i>
                </>
              )}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default FormTH;
