import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Swal from "sweetalert2";
import Country from "../../../../data/countries.json";

const FormEN = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [isLoading, setIsLoading] = useState(false);
  const validation = Yup.object().shape({
    title_en: Yup.string()
      .max(10, "must be at most 10 characters")
      .required("Required"),
    fname_en: Yup.string()
      .max(100, "must be at most 100 characters")
      .required("Required"),
    middle_name: Yup.string().max(50, "must be at most 50 characters"),
    lname_en: Yup.string()
      .max(100, "Must be at most 100 characters")
      .required("Required"),
    job: Yup.string()
      .max(100, "Must be at most 100 characters")
      .required("Required"),
    job_other: Yup.string()
      .max(50, "Must be at most 50 characters")
      .when("job", {
        is: (val) => val === "Other" || val === "Physician, (please Specify)",
        then: Yup.string().required("Required"),
        otherwise: Yup.string().nullable(true), // Optional for other job types
      }),
    email: Yup.string()
      .max(200, "Must be at most 200 characters")
      .email("Invalid email")
      .required("Required"),
    country: Yup.string().required("Required"),

    food: Yup.string()
      .max(20, "must be at most 20 characters")
      .required("Required"),
    foodallergy: Yup.string().max(300, "must be at most 300 characters"),
  });

  return (
    <>
      <Formik
        initialValues={{
          title_en: "",
          fname_en: "",
          middle_name: "",
          lname_en: "",
          job: "",
          job_other: "",
          email: "",
          country: "",
          food: "",
          foodallergy: "",
        }}
        validationSchema={validation}
        onSubmit={async (val) => {
          await setIsLoading(true);
          let formData = new FormData();
          formData.append("title_en", val.title_en);
          formData.append("fname_en", val.fname_en);
          formData.append("middle_name", val.middle_name);
          formData.append("lname_en", val.lname_en);
          formData.append("job", val.job);
          formData.append("job_other", val.job_other ? val.job_other : "");
          formData.append("email", val.email);
          formData.append("country", val.country);
          formData.append("food", val.food);
          formData.append("foodallergy", val.foodallergy);
          formData.append("lang", "en");
          formData.append("amont", 5500);
          formData.append("event_id", searchParams.get("e"));
          // console.log(val);

          Swal.fire({
            width: "450px",
            title: "System is processing",
            html: `<img src="https://global.discourse-cdn.com/sitepoint/original/3X/e/3/e352b26bbfa8b233050087d6cb32667da3ff809c.gif" width="60px"></img>`,
            icon: "info",
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
          })
            .then(
              await axios
                .post(
                  `${process.env.REACT_APP_URL_API}membercourse_Add`,
                  formData
                )
                .then((res) => {
                  if (res.status === 200) {
                    Swal.fire({
                      width: "500px",
                      title: "Thank you so much for your registration",
                      text: "After registration please contact iaoc.thailand@gmail.com for the registration process.",
                      icon: "success",
                      confirmButtonColor: "#ff6b00",
                      confirmButtonText: "Accept",
                    }).then(() => {
                      setIsLoading(false);
                      window.location.reload();
                    });
                  }
                })
                .catch((err) => {
                  Swal.fire({
                    width: "500px",
                    title:
                      err.code === "ERR_BAD_REQUEST"
                        ? err.response.data.message
                        : err.message,
                    // text: ,
                    icon: err.response ? "warning" : "error",
                    confirmButtonColor: "#ff6b00",
                    confirmButtonText: "OK",
                  }).then(() => {
                    setIsLoading(false);
                  });
                })
            )
            .then(() => {
              setIsLoading(false);
            });
        }}
      >
        {({ values, errors, touched, setValues }) => (
          <Form className="form row">
            {/* title_en */}
            <div className="col-lg-12">
              <div className="form-group">
                <label>Title Name</label>
                <Field
                  as="select"
                  name="title_en"
                  className={
                    "form-control form-control-lg" +
                    (errors.title_en && touched.title_en ? " is-invalid" : "")
                  }
                  maxLength={10}
                >
                  <option hidden>Please select choice</option>
                  <option value="Dr">Dr</option>
                  <option value="Mr">Mr</option>
                  <option value="Mrs">Mrs</option>
                  <option value="Ms">Ms</option>
                </Field>
                <ErrorMessage
                  name="title_en"
                  component="div"
                  className="invalid-feedback"
                />
              </div>
            </div>

            {/* fname_en */}
            <div className="col-lg-12">
              <div className="form-group">
                <label>First Name</label>
                <Field
                  type="text"
                  className={
                    "form-control form-control-lg" +
                    (errors.fname_en && touched.fname_en ? " is-invalid" : "")
                  }
                  name="fname_en"
                  placeholder="First Name"
                />
                <ErrorMessage
                  name="fname_en"
                  component="div"
                  className="invalid-feedback"
                />
              </div>
            </div>

            {/* middle_name_en */}
            <div className="col-lg-12">
              <div className="form-group">
                <label>Middle Name</label>
                <Field
                  type="text"
                  className={
                    "form-control form-control-lg" +
                    (errors.middle_name && touched.middle_name
                      ? " is-invalid"
                      : "")
                  }
                  name="middle_name"
                  placeholder="Middle Name"
                />
                <ErrorMessage
                  name="middle_name"
                  component="div"
                  className="invalid-feedback"
                />
              </div>
            </div>

            {/* lname_en */}
            <div className="col-lg-12">
              <div className="form-group">
                <label>Last Name</label>
                <Field
                  type="text"
                  className={
                    "form-control form-control-lg" +
                    (errors.lname_en && touched.lname_en ? " is-invalid" : "")
                  }
                  name="lname_en"
                  placeholder="Last Name"
                />
                <ErrorMessage
                  name="lname_en"
                  component="div"
                  className="invalid-feedback"
                />
              </div>
            </div>

            {/* subspecialty */}
            <div className="col-lg-12">
              <div className="form-group">
                <label>Subspecialty</label>
                <Field
                  as="select"
                  name="job"
                  className={
                    "form-control form-control-lg" +
                    (errors.job && touched.job ? " is-invalid" : "")
                  }
                  onChange={(event) => {
                    setValues({
                      ...values,
                      job: event.target.value,
                      job_other: "",
                      cme_credit: "",
                    });
                  }}
                >
                  <option hidden>Please select choice</option>
                  <option value="Radiologist">Radiologist</option>
                  <option value="Radiology Trainee">Radiology Trainee</option>
                  <option value="Pediatrician">Pediatrician</option>
                  <option value="Pediatric Trainee">Pediatric Trainee</option>
                  <option value="Technologist">Technologist</option>
                  <option value="Physicist">Physicist</option>
                  <option value="Nurse">Nurse</option>
                  <option value="Physician, (please Specify)">
                    Physician, (please Specify)
                  </option>
                  <option value="Other">Other</option>
                </Field>
                <ErrorMessage
                  name="job"
                  component="div"
                  className="invalid-feedback"
                />
              </div>
            </div>

            {/* job_other */}
            {values.job === "Other" ||
            values.job === "Physician, (please Specify)" ? (
              <>
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>Other</label>
                    <Field
                      type="text"
                      className={
                        "form-control form-control-lg" +
                        (errors.job_other && touched.job_other
                          ? " is-invalid"
                          : "")
                      }
                      name="job_other"
                      placeholder="Please specify"
                    />
                    <ErrorMessage
                      name="job_other"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                </div>
              </>
            ) : (
              ""
            )}

            {/* Email */}
            <div className="col-lg-12">
              <div className="form-group">
                <label>Email</label>
                <Field
                  type="text"
                  className={
                    "form-control form-control-lg" +
                    (errors.email && touched.email ? " is-invalid" : "")
                  }
                  name="email"
                  placeholder="Email"
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="invalid-feedback"
                />
              </div>
            </div>

            {/* Country */}
            <div className="col-lg-12">
              <div className="form-group">
                <label>Country</label>
                <Field
                  as="select"
                  name="country"
                  className={
                    "form-control form-control-lg" +
                    (errors.country && touched.country ? " is-invalid" : "")
                  }
                >
                  <option hidden>Please select choice</option>
                  {Country.map((val) => (
                    <option value={val.code}>{val.name}</option>
                  ))}
                </Field>
                <ErrorMessage
                  name="country"
                  component="div"
                  className="invalid-feedback"
                />
              </div>
            </div>

            {/* Food */}
            <div className="col-lg-12">
              <div className="form-group">
                <label>Food Options</label>
                <Field
                  as="select"
                  name="food"
                  className={
                    "form-control form-control-lg" +
                    (errors.food && touched.food ? " is-invalid" : "")
                  }
                  maxLength={10}
                >
                  <option hidden>Please select choice</option>
                  <option value="Regular meal">Regular meal</option>
                  <option value="Vegetarian meal">Vegetarian meal</option>
                  <option value="Muslim meal">Muslim meal</option>
                </Field>
                <ErrorMessage
                  name="food"
                  component="div"
                  className="invalid-feedback"
                />
              </div>
            </div>

            {/* foodallergy */}
            <div className="col-lg-12">
              <div className="form-group">
                <label>Food Allergies (optional)</label>
                <Field
                  type="text"
                  className={
                    "form-control form-control-lg" +
                    (errors.foodallergy && touched.foodallergy
                      ? " is-invalid"
                      : "")
                  }
                  name="foodallergy"
                  placeholder="Food Allergie"
                />
                <ErrorMessage
                  name="foodallergy"
                  component="div"
                  className="invalid-feedback"
                />
              </div>
            </div>

            <div>
              <h5 style={{ color: "red", fontWeight: "bold" }}>
                *After registration please contact iaoc.thailand@gmail.com for
                the registration process.
              </h5>
            </div>

            <div
              className="col-lg-12 mt-4"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <button
                disabled={isLoading}
                className={`rn-btn edu-btn ${isLoading ? "disabled-link" : ""}`}
                type="submit"
              >
                {isLoading ? (
                  <>
                    <div className="loader"></div>
                    <div className="px-3">Loading...</div>
                  </>
                ) : (
                  <>
                    <span>Register</span>
                    <i className="icon-arrow-right-line-right"></i>
                  </>
                )}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default FormEN;
