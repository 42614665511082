import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./assets/scss/style.scss";
import ScrollToTop from "./components/scrolltotop/ScrollToTop";
import Home from "./pages/homepages/Home";
import About from "./pages/innerpages/AboutUsOne";
import Gallery from "./pages/blog/BlogGridOne";
import GalleryGrid from "./pages/innerpages/GalleryGrid";
import ContactMe from "./pages/innerpages/ContactMe";
import Register from "./pages/innerpages/Register";
import ForgotPassword from "./pages/ForgotPassword";
import Resetpassword from "./pages/Resetpassword";
import Error from "./pages/innerpages/Error";
import Event from "./pages/innerpages/Event";
import EventDetail from "./pages/detailspages/EventDetails";
import CheckRegistration from "./pages/innerpages/components/register/CheckRegistration";
// const Home = lazy(() => import("./pages/homepages/Home"));
// const About = lazy(() => import("./pages/innerpages/AboutUsOne"));
// const Gallery = lazy(() => import("./pages/blog/BlogGridOne"));
// const GalleryGrid = lazy(() => import("./pages/innerpages/GalleryGrid"));
// const ContactMe = lazy(() => import("./pages/innerpages/ContactMe"));
// const Register = lazy(() => import("./pages/innerpages/Register"));
// const Error = lazy(() => import("./pages/innerpages/Error"));

function App() {
  return (
    <Router basename={"/iaoc"}>
      <ScrollToTop>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/gallery" element={<Gallery />} />
          <Route exact path="/contact-me" element={<ContactMe />} />
          <Route exact path="/gallery-detail/:id" element={<GalleryGrid />} />
          <Route exact path="/forgotpassword" element={<ForgotPassword />} />
          <Route exact path="/resetpassword" element={<Resetpassword />} />
          <Route exact path="/event" element={<Event />} />
          <Route exact path="/event/:id" element={<EventDetail />} />
          <Route exact path="/event/register" element={<Register />} />
          <Route
            exact
            path="/event/check-registration"
            element={<CheckRegistration />}
          />
          {/* <Route exact path="/login" element={<Login />} /> */}
          <Route path="*" element={<Error />} />
          <Route path="/not-found" element={<Error />} />
        </Routes>
      </ScrollToTop>
    </Router>
  );
}

export default App;

/* <React.Suspense
  fallback={
    <div className="loading-container">
      <div className="spinner"></div>
    </div>
  }
></React.Suspense>; */
