import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

const AboutSeven = () => {
  return (
    <div className=" eduvibe-home-four-about edu-about-area about-style-2 edu-section-gap bg-color-white">
      <div className="container eduvibe-animated-shape">
        <div className="row row--50">
          <div className="col-lg-6">
            <div className="about-image-gallery">
              <div className="eduvibe-about-1-img-wrapper">
                <div className="loader-container">
                  <div className="spinner"></div>
                </div>
                <img
                  className="image-1"
                  src={process.env.PUBLIC_URL + "/images/about/about-1.webp"}
                  alt="About Images"
                />
                <span className="eduvibe-about-blur">
                  <img
                    src={
                      process.env.PUBLIC_URL + "/images/about/about-blur.png"
                    }
                    alt="about-blur"
                  />
                </span>
              </div>
              <div className="circle-image-wrapper">
                <img
                  className="image-2"
                  src={process.env.PUBLIC_URL + "/images/about/about-2.webp"}
                  alt="about-images"
                />
                <div className="circle-image">
                  <span></span>
                </div>
              </div>
              <div className="finished-session">
                <div className="inner">
                  <div className="text">IAOC</div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <ScrollAnimation
              animateIn="fadeInUp"
              delay={100}
              animateOnce={true}
            >
              <div className="inner mt_md--40 mt_sm--40">
                <div className="section-title text-start">
                  <span className="pre-title">About Us</span>
                  <h3 className="title">Imaging Academic Outreach Center</h3>
                </div>
                <p className="description mt--40 mt_md--20 mt_sm--20">
                  iAOC was founded in 2016 with the mission to establish the
                  life-long learning, self-sustainable and affordable
                  educational center for radiologists, technologists and medical
                  personals for Thailand and neighborhood countries.
                </p>
              </div>
            </ScrollAnimation>
          </div>
        </div>

        <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
          <div className="shape-image shape-image-1">
            <img
              src={process.env.PUBLIC_URL + "/images/shapes/shape-11-05.png"}
              alt="Shape Thumb"
            />
          </div>
          <div className="shape-image shape-image-2">
            <img
              src={process.env.PUBLIC_URL + "/images/shapes/shape-08-01.png"}
              alt="Shape Thumb"
            />
          </div>
          <div className="shape-image shape-image-3">
            <img
              src={process.env.PUBLIC_URL + "/images/shapes/shape-30.png"}
              alt="Shape Thumb"
            />
          </div>
          <div className="shape shape-1">
            <span className="shape-dot"></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSeven;
