import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../../common/breadcrumb/BreadcrumbOne";
import FormEN from "./components/register/FormEN";
import FormTH from "./components/register/FormTH";
import EventData from "../../data/event/EventData.json";

const Register = () => {
  const navigate = useNavigate();
  const BreadcrumbItems = [
    { url: "/", label: "Home" },
    { url: "/event", label: "Event" },
    { url: "/register", label: "Register", active: true },
  ];
  const [searchParams, setSearchParams] = useSearchParams();

  const [chklang, setChklang] = useState();

  const data = EventData.filter(
    (event) => event.id === Number(searchParams.get("e"))
  );

  useEffect(() => {
    if (!data[0].status) {
      navigate(`/event/${Number(searchParams.get("e"))}`);
    }
  }, [data]);

  return (
    <>
      <SEO title={`Register  || ${data[0].title}`} description="register" />
      <Layout>
        <BreadcrumbOne title="Register" items={BreadcrumbItems} />
        <div className="edu-section-gap bg-color-white">
          <div className="container eduvibe-animated-shape">
            <div className="row g-5">
              <div className="section-title text-start mb--60">
                <h3 className="title mb-4">{data[0].title}</h3>
                <img src={data[0].image} alt="Event Thumb" />
              </div>

              {/* <div>
                <div>รอบปกติ</div>
                <div>ราคา 7500 </div>
                <></>
              </div>

              <div>
                <div>รอบพิเศษ</div>
                <div>7500</div>
              </div> */}

              <div className="section-register-title">
                <p>Please Choose Your Language : </p>
                <div className="section-register-choice">
                  <div>
                    <input
                      type="radio"
                      id="th"
                      name="lang"
                      onClick={() => setChklang("th")}
                      value={chklang}
                    />
                    <label htmlFor="th">TH *เก็บคะแนน CME</label>
                  </div>

                  <div>
                    <input
                      type="radio"
                      id="en"
                      name="lang"
                      onClick={() => setChklang("en")}
                      value={chklang}
                    />
                    <label htmlFor="en">EN</label>
                  </div>
                </div>
              </div>

              {chklang === "th" ? (
                <>
                  <div className="section-register-form">
                    <FormTH />
                  </div>
                </>
              ) : (
                ""
              )}

              {chklang === "en" ? (
                <div className="section-register-form">
                  <FormEN />
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Register;
