import React from "react";
import { Link } from "react-router-dom";
import ScrollTopButton from "./ScrollTopButton";

const FooterOne = () => {
  return (
    <>
      <footer className="eduvibe-footer-one edu-footer footer-style-default">
        <div className="footer-top">
          <div className="container eduvibe-animated-shape">
            <div className="row g-5">
              <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                <div className="edu-footer-widget">
                  <div className="logo">
                    <Link to={process.env.PUBLIC_URL + "/"}>
                      <img
                        className="logo-light"
                        src={
                          process.env.PUBLIC_URL +
                          "/images/logo/logo-footer.png"
                        }
                        alt="Footer Logo"
                      />
                    </Link>
                  </div>
                  <p className="description">
                    iAOC was founded in 2016 with the mission to establish the
                    life-long learning, self-sustainable and affordable
                    educational center for radiologists, technologists and
                    medical personals for Thailand and neighborhood countries.
                  </p>
                  <ul className="social-share">
                    <li>
                      <a
                        href="https://www.facebook.com/iAOCofficial.TH/"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <i className="icon-Fb"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.youtube.com/channel/UCjaNO2ANDYCF060_WVSXvTg"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <i className="icon-youtube"></i>
                      </a>
                    </li>
                    {/* <li>
                      <a href="#">
                        <i className="icon-Pinterest"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="icon-Twitter"></i>
                      </a>
                    </li> */}
                  </ul>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                <div className="edu-footer-widget explore-widget">
                  <h5 className="widget-title">Explore</h5>
                  <div className="inner">
                    <ul className="footer-link link-hover">
                      <li>
                        <Link to="/about-us-1">
                          <i className="icon-Double-arrow"></i>Gallery
                        </Link>
                      </li>
                      <li>
                        <a href="https://moodle.cra.ac.th/login/index.php">
                          <i className="icon-Double-arrow"></i>Moodle
                        </a>
                      </li>
                      <li>
                        <a href="https://www.canva.com/design/DAFFDuH1mRc/asxbJ42f4H1jJNEEc1H2oQ/view?utm_content=DAFFDuH1mRc&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink">
                          <i className="icon-Double-arrow"></i>How To Ues Moodle
                        </a>
                      </li>
                      <li>
                        <Link to="/contact-me">
                          <i className="icon-Double-arrow"></i>Contact
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                <div className="edu-footer-widget quick-link-widget">
                  <h5 className="widget-title">PARTNER</h5>
                  <div className="inner">
                    <ul className="footer-link link-hover">
                      <li>
                        <a
                          rel="noopener noreferrer"
                          target="_blank"
                          href="https://www.facebook.com/RAD.Association/"
                        >
                          <i className="icon-Double-arrow"></i>ข่าวดังรังสี
                        </a>
                      </li>
                      <li>
                        <a
                          rel="noopener noreferrer"
                          target="_blank"
                          href="https://www.facebook.com/SonographerSchool"
                        >
                          <i className="icon-Double-arrow"></i>Sonographer
                          school CRA
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                <div className="edu-footer-widget">
                  <h5 className="widget-title">Contact Info</h5>
                  <div className="inner">
                    <div className="widget-information">
                      <ul className="information-list">
                        <li>
                          <i className="icon-map-pin-line"></i>906 Kamphaeng
                          Phet 6 Talat Bang Khen Lak Si Bangkok 10210
                        </li>
                        <li>
                          <i className="icon-phone-fill"></i>
                          <a href="#">02-576-6000 ต่อ 8753</a>
                        </li>
                        <li>
                          <i className="icon-mail-line-2"></i>
                          <a href="#">iaoc.thailand@gmail.com</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="shape-dot-wrapper shape-wrapper d-md-block d-none">
              <div className="shape-image shape-image-1">
                <img
                  src={
                    process.env.PUBLIC_URL + "/images/shapes/shape-21-01.png"
                  }
                  alt="Shape Thumb"
                />
              </div>
              <div className="shape-image shape-image-2">
                <img
                  src={process.env.PUBLIC_URL + "/images/shapes/shape-35.png"}
                  alt="Shape Thumb"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="copyright-area copyright-default">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner text-center">
                  <p>
                    Copyright 2022 - {new Date().getFullYear()}{" "}
                    <a href="#">IAOC</a> Designed By <a href="#">TWC</a>. All
                    Rights Reserved
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <ScrollTopButton />
    </>
  );
};

export default FooterOne;
