import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../../common/breadcrumb/BreadcrumbOne";
import GalleryItem from "../../components/gallery/GalleryItem";
import { DataService } from "../../config/dataService";

const GalleryGrid = () => {
  const params = useParams();
  const [galleryData, setGalleryData] = useState([]);
  const [title, setTitle] = useState("");
  const BreadcrumbItems = [
    { url: "/", label: "Home" },
    { url: "/gallery", label: "Gallery" },
    { url: "/gallery-detail", label: "Album", active: true },
  ];

  useEffect(() => {
    const fetchData = async () => {
      const res = await DataService.get(
        `getGalleryDetail?galleryID=${params.id}`
      );
      setGalleryData(res.data.data);
      setTitle(res.data.title);
    };
    fetchData();
  }, [params.id]);

  return (
    <>
      <SEO title={title} description={title} />
      <Layout>
        <BreadcrumbOne title="Album" items={BreadcrumbItems} />
        <div className="edu-gallery-grid-area masonary-wrapper-activation edu-section-gap bg-image overflow-hidden">
          <div className="container">
            <div className="row g-5 align-items-center">
              <div className="col-lg-12">
                {/* <div className="content-blog-top">
                  <div className="content-status-top d-flex justify-content-between mb--30 align-items-center">
                    <ul className="blog-meta">
                      <li>
                        <i className="icon-calendar-2-line"></i>
                        item
                      </li>
                      <li>
                        <i className="icon-discuss-line"></i>
                        dasd
                      </li>
                      <li>
                        <i className="icon-time-line"></i>
                        dsadsa
                      </li>
                    </ul>
                  </div>
                </div> */}
                <div className="section-title text-start">
                  <h3 className="title">{title}</h3>
                </div>
              </div>
            </div>

            <div className="eduvibe-gallery-items row g-5 mt--5">
              {galleryData.map((item, index) => (
                <div key={item.img_id} className="col-lg-4 col-md-6 col-12">
                  <GalleryItem data={item} slide={index} />
                </div>
              ))}
            </div>

            <div className="col-lg-12 mt-5">
              <div className="load-more-btn text-center">
                <a
                  className="edu-btn"
                  href="https://www.facebook.com/iAOCofficial.TH/photos"
                  target="_blank"
                  rel="noreferrer"
                >
                  See All
                  <i className="icon-arrow-right-line-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default GalleryGrid;
