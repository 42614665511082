import React from "react";
import SEO from "../../common/SEO";
import HeaderOne from "../../common/header/HeaderOne";
import BreadcrumbOne from "../../common/breadcrumb/BreadcrumbOne";
import AboutSeven from "../../components/about/AboutSeven";
import FooterOne from "../../common/footer/FooterOne";

const AboutUsOne = () => {
  const BreadcrumbItems = [
    { url: "/", label: "Home" },
    { url: "/about", label: "About Us", active: true },
  ];
  return (
    <>
      <SEO title="About Us" description={"About Us"} />

      <HeaderOne />

      <BreadcrumbOne title="About Us" items={BreadcrumbItems} />

      <AboutSeven />

      {/* <AboutSix /> */}

      {/* <AboutUsOneTeam /> */}

      {/* <AboutUsOneService /> */}

      <FooterOne />
    </>
  );
};

export default AboutUsOne;
